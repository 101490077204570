<template>
  <div class="content-tab-facture-libre" ref="content">
    <div class="body-box-rapport" style="width:100%">
      <div class="tabs-body-style ">
        <div class="style-block--filter">
          <router-link
            variant="success"
            class="config-btn ml-2 mt-1 mb-1 add-facture-style"
            to="/add-facture"
            v-if="cantCreate($options.name)"
          >
            Nouvelle facture</router-link
          >
          <div class="style-filter-div">
            <b-form-group
              label="N°"
              class="input-modal-champ mt-3 mb-1 style-filtre"
            >
              <b-form-input
                autocomplete="off"
                id="num"
                placeholder="Num Facture"
                v-model="num"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Objet"
              class="input-modal-champ mt-3 mb-1 style-filtre"
            >
              <b-form-textarea
                autocomplete="off"
                id="objet"
                placeholder="Objet Facture"
                v-model="objet"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group
              label="Type"
              class="input-modal-champ mt-1 mb-1 style-filtre"
            >
              <!-- <b-form-select
              id="type"
              required
              :options="typeFacture"
              class="b-form-select-raduis"
              v-model="type"
            ></b-form-select> -->
              <multiselect
                v-model="type"
                :options="typeFacture"
                :multiple="true"
                label="text"
                track-by="value"
                class="customSelectMultipleFilter libres"
                :showLabels="false"
                placeholder="sélectionner"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                @input="selectAllType()"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length && values.some(e => e.text != 'Tous')"
                    >{{ values.length }} options selected</span
                  >
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.some(e => e.text == 'Tous')"
                    >Tous selectionnée</span
                  >
                </template>
              </multiselect>
            </b-form-group>

            <b-form-group
              label="Etat "
              class="input-modal-champ mt-1 mb-1 style-filtre"
            >
              <!-- <b-form-select
              id="etat"
              :options="etatListFilter"
              class="b-form-select-raduis "
              v-model="etat"
            ></b-form-select> -->
              <multiselect
                v-model="etat"
                :options="etatListFilter"
                :multiple="true"
                label="text"
                track-by="value"
                class="customSelectMultipleFilter libres"
                :showLabels="false"
                placeholder="sélectionner"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                @input="selectAllEtat()"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length && values.some(e => e.text != 'Tous')"
                    >{{ values.length }} options selected</span
                  >
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.some(e => e.text == 'Tous')"
                    >Tous selectionnée</span
                  >
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Mode de règlement"
              class="input-modal-champ mt-1 mb-1 style-filtre"
            >
              <!-- <b-form-select
              id="mode_reglement"
              required
              :options="modeReglementList"
              class="b-form-select-raduis"
              v-model="mode_reglement"
            ></b-form-select> -->
              <multiselect
                v-model="mode_reglement"
                :options="modeReglementList"
                :multiple="true"
                label="text"
                track-by="value"
                class="customSelectMultipleFilter libres"
                :showLabels="false"
                placeholder="sélectionner"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                @input="selectAllMode()"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length && values.some(e => e.text != 'Tous')"
                    >{{ values.length }} options selected</span
                  >
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.some(e => e.text == 'Tous')"
                    >Tous selectionnée</span
                  >
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Vendeur"
              class="input-modal-champ mt-1 mb-1 style-filtre"
              v-if="computedcheckRolePermission"
            >
              <!-- <b-form-select
              :options="[
                { text: 'Tous', value: null },
                ...computegetSettingFilialesTh
              ]"
              v-model="vendeur"
              class="b-form-select-raduis "
              text-field="text"
              value-field="value"
            ></b-form-select> -->

              <multiselect
                v-model="vendeur"
                :options="[
                  { text: 'Tous', value: null },
                  ...computegetSettingFilialesTh
                ]"
                :multiple="true"
                label="text"
                track-by="value"
                class="customSelectMultipleFilter libres"
                :showLabels="false"
                placeholder="sélectionner"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                @input="selectAllVendeur()"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length && values.some(e => e.text != 'Tous')"
                    >{{ values.length }} options selected</span
                  >
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.some(e => e.text == 'Tous')"
                    >Tous selectionnée</span
                  >
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Acheteur"
              class="input-modal-champ mt-1 mb-1 style-filtre"
              v-if="computedcheckRolePermission"
            >
              <!-- <b-form-select
              :options="[
                { text: 'Tous', value: null },
                ...computedGetAcheteurs
              ]"
              v-model="acheteur"
              class="b-form-select-raduis "
              text-field="text"
              value-field="value"
            ></b-form-select> -->
              <multiselect
                v-model="acheteur"
                :options="[
                  { text: 'Tous', value: null },
                  ...computedGetAcheteurs
                ]"
                :multiple="true"
                label="text"
                track-by="value"
                class="customSelectMultipleFilter libres"
                :showLabels="false"
                placeholder="sélectionner"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                @input="selectAllAcheteur()"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length && values.some(e => e.text != 'Tous')"
                    >{{ values.length }} options selected</span
                  >
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.some(e => e.text == 'Tous')"
                    >Tous selectionnée</span
                  >
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Catégorie"
              class="input-modal-champ mt-1 mb-1 style-filtre"
            >
              <!-- <b-form-select
              id="categorie"
              v-model="categorie"
              :options="[
                { text: 'Tous', value: null },
                ...computeGetcategories
              ]"
              text-field="text"
              value-field="value"
              class="b-form-select-raduis"
            ></b-form-select> -->
              <multiselect
                v-model="categorie"
                :options="[
                  { text: 'Tous', value: null },
                  ...computeGetcategories
                ]"
                :multiple="true"
                label="text"
                track-by="value"
                class="customSelectMultipleFilter libres"
                :showLabels="false"
                placeholder="sélectionner"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                @input="selectAllCategory()"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.length && values.some(e => e.text != 'Tous')"
                    >{{ values.length }} options selected</span
                  >
                  <span
                    :class="{
                      'multiselect__single showBlock': !isOpen,
                      'multiselect__single ': isOpen
                    }"
                    v-if="values.some(e => e.text == 'Tous')"
                    >Tous selectionnée</span
                  >
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Date début"
              label-for="dateDebut"
              class="input-modal-champ mt-1 mb-1 style-filtre"
            >
              <date-picker
                value-type="format"
                type="date"
                v-model="date_debut"
                :clear-button="true"
                :disabled-date="disabledStartDateFilter"
              ></date-picker>
            </b-form-group>
            <b-form-group
              label="Date fin"
              label-for="dateFin"
              class="input-modal-champ mt-1 mb-1 style-filtre"
            >
              <date-picker
                value-type="format"
                type="date"
                v-model="date_fin"
                :clear-button="true"
                :disabled-date="disabledEndDateFiltre"
              ></date-picker>
            </b-form-group>
          </div>
          <button class="button-13 mt-4 mb-2 ml-4" @click="filterSearch">
            Recherche
          </button>
        </div>
      </div>

      <div class="table-rapport-style">
        <div class="row header-component-facture-libre">
          <div class="error-message ml-3">
            <div
              class="chargement-message-text "
              v-if="isLoading || getFactureLibreLoadingTh"
            >
              Chargement en cours
            </div>
            <div v-if="getFactureLibreErroresTh" class="error">
              <ul v-if="Array.isArray(getFactureLibreErroresTh)" class="mb-0">
                <li v-for="(e, index) in getFactureLibreErroresTh" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getFactureLibreErroresTh }}</div>
            </div>
          </div>
          <!-- <font-awesome-icon icon="arrows-rotate" /> -->
          <div class="d-flex justify-content-center">
            <b-button
              size="sm"
              class="button-export-style  mr-2 ml-2"
              title="Vider l'ordre"
              @click="refrech()"
            >
              <img
                src="@/assets/arrows_rotate.png"
                style="height: 20px;width: 20px;"
            /></b-button>
            <div
              v-if="getLoadingMailFactureLibre"
              class="loading mt-1 mr-2 ml-2"
            >
              <div class="spinner-border loadingFetch" role="status"></div>
            </div>
            <b-button
              size="sm"
              class="button-export-style  mr-2 ml-2"
              title="Envoyez un email aux sociéte sélectionnés"
              @click="openModal"
              v-if="
                computedGetDataToUse.length > 0 && !getLoadingMailFactureLibre
              "
            >
              <font-awesome-icon icon="envelope"
            /></b-button>
            <mail-modal
              :isOpen="isOpen"
              :computedGetDataToUse="computedGetDataToUse"
              @custumChange="closeModal"
              @handleSendMail="handleSendMail"
            />
            <b-dropdown size="sm" class="p-0 mr-1">
              <template #button-content>Télécharger/Confirmer</template>
              <b-dropdown-item
                v-if="
                  (computedCheck || factureAvoirChecked.length > 0) &&
                    cantExport($options.name)
                "
                @click.prevent.stop="FacuresToDownloadPDF()"
              >
                PDF
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  (computedCheck || factureAvoirChecked.length > 0) &&
                    cantExport($options.name)
                "
                @click.prevent.stop="FacuresToDownloadZIP()"
              >
                ZIP
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  (computedCheck || factureAvoirChecked.length > 0) &&
                    cantExport($options.name)
                "
                @click.prevent.stop="FacuresToConfirme()"
              >
                Confirmer
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.exportXlsModal @click="initModal">
                Exporter xls
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- <h5>Revenus / Tous</h5> -->
        </div>
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableFraisFactureLibre"
          :fields="fields"
          :items="getFacturesLibresTh"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template class="check-th" v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                v-model="checkAll"
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </div>
            <div
              v-else
              :class="{
                'd-flex justify-content-center align-items-center':
                  data.field.isSortable === true,
                'd-flex justify-content-center':
                  data.field.isSortable === false,
                'underline-selected-colomn': data.field.key === column
              }"
            >
              {{ data.field.label }}

              <font-awesome-icon
                v-if="data.field.isSortable === true"
                @click="listOrder(data.field.key, 'ASC')"
                icon="arrow-up"
                :class="
                  data.field.key === column && order === 'ASC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
              {{ ' ' }}
              <font-awesome-icon
                @click="listOrder(data.field.key, 'DESC')"
                v-if="data.field.isSortable === true"
                icon="arrow-down"
                :class="
                  data.field.key === column && order === 'DESC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
            </div>
          </template>
          <template v-slot:cell(check_all)="data"
            >{{ data.item.check_all }}
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkOneFunction(data.item.check)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data">
            <button
              class="btn btn-anomalie"
              v-if="data.item.FactureAvoir.length > 0"
              @click="data.toggleDetails"
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les avoirs"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les avoirs"
              />
            </button>
          </template>

          <template #row-details="data">
            <span v-if="data.item.FactureAvoir.length === 0"
              >Pas des factures avoir pour cette facture
            </span>
            <span
              v-else
              v-for="item in data.item.FactureAvoir"
              :key="item.id"
              :class="
                item.motif_avoir === 'avoir_global'
                  ? 'couleur-facture-global align-td-item border-botom'
                  : 'align-td-item border-botom'
              "
            >
              <td class="width-service">
                <div>
                  <b-form-checkbox
                    v-model="item.checkAvoir"
                    @change="checkOneAvoirFunction(item)"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="width-service fix-margin-td-avoir">
                <div
                  class="block-service-style d-flex justify-content-center"
                ></div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.type }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.vendeur.nom_compagnie }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.num }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_creation }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{
                    item &&
                    item.acheteur &&
                    item.acheteur.nom_compagnie === null
                      ? item.acheteur.prenom + ' ' + item.acheteur.nom_famille
                      : item && item.acheteur && item.acheteur.nom_compagnie
                      ? item.acheteur.nom_compagnie
                      : ' '
                  }}
                </div>
              </td>

              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ data.item.etat == 'Annulée' ? 'Verrouillée' : '-' }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_paiement }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{
                    data.item.categorie && data.item.categorie.nom_categorie
                      ? data.item.categorie.nom_categorie
                      : '-'
                  }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center ">
                  {{ item.total_net_ht_data_base }} {{ item.devise }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center ">
                  {{ item.total_ttc_data_base }} {{ item.devise }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center ">
                  {{ item.confirme === true ? 'OUI' : 'NON' }}
                </div>
              </td>
              <td class="fix-margin-td-avoir">
                <div
                  class="block-service-style d-flex justify-content-center "
                ></div>
              </td>

              <td class="fix-margin-td-avoir">
                <div
                  class="block-service-style d-flex justify-content-center m-1"
                >
                  <b-dropdown active="false" size="sm" class="p-0">
                    <template #button-content class="button-13">
                      <font-awesome-icon icon="bars" />
                    </template>
                    <b-dropdown-item @click.prevent.stop="visualisation(item)">
                      Afficher
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="
                        cantUpdate($options.name) &&
                          item.motif_avoir !== 'avoir_global'
                      "
                    >
                      <router-link :to="`/update-facture/${item.id}`">
                        Modifier
                      </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="cantExport($options.name)"
                      @click.prevent.stop="FacuresToDownloadOnePDF(item)"
                    >
                      Télécharger en pdf
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </span>
          </template>

          <template v-slot:cell(type)="data">
            {{ data.item.type }}
          </template>
          <template v-slot:cell(Vendeur)="data">
            {{ data.item.vendeur.nom_compagnie }}
          </template>
          <template v-slot:cell(num)="data">
            {{ data.item.num }}
          </template>
          <template v-slot:cell(total_net_ht)="data">
            {{ data.item.total_net_ht_data_base }} {{ data.item.devise }}
          </template>
          <template v-slot:cell(total_ttc)="data">
            {{ data.item.total_ttc_data_base }} {{ data.item.devise }}
          </template>
          <template v-slot:cell(etat)="data">
            <!-- <b-form-select
              :class="{
                'red-color-custom-select': data.item.etat == 'Créé',
                'green-color-custom-select': data.item.etat == 'Payé'
              }"
              id="etat"
              :options="etatListForEachFacute"
              class="b-form-select-raduis "
              v-model="data.item.etat"
              @change="updateEtat(data.item)"
            ></b-form-select> -->
            <div
              class="custom-component-etat-facture"
              :class="{ open: data.item.openSelect == true }"
              v-click-outside="closeSelectEtat"
              :id="'component' + data.index"
            >
              <div
                :id="'etat-facture-' + data.item.id"
                class="b-form-select-custom-etat "
                @click="setVisibilityEtatList(data.item, data.index)"
                :class="{
                  'red-color-custom-select': data.item.etat == 'Payé en partie',
                  'white-color-custom-select': data.item.etat == 'Créé',
                  'green-color-custom-select': data.item.etat == 'Payé',
                  'blue-color-custom-select': data.item.etat == 'Envoyé'
                }"
              >
                <div id="content">
                  {{ data.item.etat }}
                  <font-awesome-icon
                    icon="caret-down"
                    class="icon-class-caret-down"
                  />
                </div>
                <div
                  id="background"
                  :class="{
                    'white-color-custom-select': data.item.etat == 'Créé',
                    'green-color-custom-select':
                      data.item.etat == 'Payé' ||
                      data.item.etat == 'Payé en partie',
                    'blue-color-custom-select': data.item.etat == 'Envoyé'
                  }"
                  :style="computedWidth(data.item)"
                ></div>
              </div>

              <ul
                class="dropdown-menu pull-right "
                role="menu"
                v-if="
                  data.item.openSelect == true && data.item.etat != 'Annulée'
                "
              >
                <li
                  v-for="result in etatList"
                  :key="result.text + data.item.id"
                  @click="updateEtat(data.item, result)"
                >
                  {{ result.text }}
                </li>
              </ul>
            </div>
          </template>
          <!-- <template v-slot:cell(produit)="data">
            <div class="three-points-concats ml-3">
              {{ computedListProduct(data.item) }}
            </div>
          </template> -->
          <template v-slot:cell(Acheteur)="data">
            {{
              data.item &&
              data.item.acheteur &&
              data.item.acheteur.nom_compagnie === null
                ? data.item.acheteur.prenom +
                  ' ' +
                  data.item.acheteur.nom_famille
                : data.item &&
                  data.item.acheteur &&
                  data.item.acheteur.nom_compagnie
                ? data.item.acheteur.nom_compagnie
                : ' '
            }}
          </template>
          <template v-slot:cell(date_creation)="data">
            {{ data.item.date_creation | formateDate }}
          </template>
          <template v-slot:cell(date_paiement)="data">
            {{
              data.item && data.item.date_paiement
                ? data.item.date_paiement
                : '-'
            }}
          </template>
          <template v-slot:cell(Categorie)="data">
            {{
              data.item.categorie && data.item.categorie.nom_categorie
                ? data.item.categorie.nom_categorie
                : '-'
            }}
          </template>
          <template v-slot:cell(confirme)="data">
            {{ data.item.confirme === true ? 'OUI' : 'NON' }}
          </template>
          <template v-slot:cell(num_proforma)="data">
            {{
              data.item && data.item.num_proforma ? data.item.num_proforma : '-'
            }}
          </template>
          <template v-slot:cell(facture)> </template>
          <template v-slot:cell(action)="data">
            <b-dropdown active="false" size="sm" class="p-0">
              <template #button-content class="button-13">
                <font-awesome-icon icon="bars" />
              </template>
              <b-dropdown-item @click.prevent.stop="visualisation(data.item)">
                Afficher
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  (isSuperAdminGetter ||
                    (cantUpdate($options.name) &&
                      (data.item.user_id === idConnecter ||
                        computeVendeursNames.includes(
                          data.item.vendeur.nom_compagnie
                        )))) &&
                    data.item.confirme === false &&
                    data.item.FactureAvoir.length === 0
                "
              >
                <router-link :to="`/update-facture/${data.item.id}`">
                  Modifier
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="cantExport($options.name)"
                @click.prevent.stop="FacuresToDownloadOnePDF(data.item)"
              >
                Télécharger en pdf
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  isSuperAdminGetter ||
                    (data.item.type !== 'Facture d\'avoir' &&
                      cantDuplicate($options.name) &&
                      !data.item.FactureAvoir.some(
                        e => e.motif_avoir === 'avoir_global'
                      ) &&
                      (data.item.user_id === idConnecter ||
                        rolesToDuplicate.includes(connectedRole)))
                "
              >
                <router-link :to="`/duplicate-facture/${data.item.id}`">
                  Dupliquer facture
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  ((cantAvoir($options.name) &&
                    (isSuperAdminGetter ||
                      computeVendeursNames.includes(
                        data.item.vendeur.nom_compagnie
                      ))) ||
                    data.item.user_id === idConnecter) &&
                    data.item.FactureAvoir.length === 0
                "
                @click.prevent.stop="CreateGlobalAvoir(data.item)"
              >
                Avoir sur Facture
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.type === 'Facture proforma'"
                @click.prevent.stop="trasformFacture(data.item.id)"
              >
                Convertir Proforma
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getFactureLibreTotalRowsTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :hide-footer="true"
      no-close-on-backdrop
      id="exportXlsModal"
      title="Exportation xls"
      ref="refExportXlsModal"
    >
      <div class="d-flex justify-content-start text-dark mt-1">
        <p>Colonnes à afficher dans l'xls:</p>
      </div>
      <div class="row">
        <div class="col-6">
          <b-form-group label="Colonnes de facture:">
            <b-form-checkbox
              v-model="checkAllColumn"
              switch
              @change="changeAllCheckColumns"
              >Tous</b-form-checkbox
            >
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="selected"
              :options="options"
              name="flavour-1"
              stacked
              switches
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Colonnes des produits:">
            <b-form-checkbox
              v-model="checkAllColumnF"
              switch
              @change="changeAllCheckColumns"
              >Tous</b-form-checkbox
            >
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              :options="optionProduct"
              name="flavour-1"
              stacked
              switches
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError d-flex justify-content-center">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button class="button-valide-style " @click="handleExportFile">
            <span>
              Générer
              <div v-if="getFactureLibreLoadingTh" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
import DatePicker from 'vue2-datepicker';
import MailModal from './MailModal.vue';
export default {
  name: 'Facture-libre',
  data() {
    return {
      selected: [],
      checkAllColumn: true,
      checkAllColumnF: true,
      error: null,
      pdfSource: null,
      isLoading: false,
      num: null,
      type: [],
      etat: [],
      vendeur: [],
      categorie: [],
      acheteur: [],
      mode_reglement: [],
      date_debut: null,
      date_fin: null,
      objet: null,
      isOpen: false,
      rolesToDuplicate: [
        'responsable_controle_de_gestion',
        'admin',
        'responsable_finance'
      ],
      idConnecter: null,
      connectedRole: null,
      column: 'id',
      order: 'DESC',
      checkAll: false,
      dataToUse: [],
      professionnelSocite: [],
      factureAvoirChecked: [],
      etatList: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' }
        // { value: 'Annulée', text: 'Annulée' }
      ],
      etatListFilter: [
        { value: null, text: 'Tous' },
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ],
      indexSelectIsOpen: null,
      perPage: 15,
      page: 1,
      perPageList: [
        { value: 15, text: 15 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'width-service',
          tdClass: 'width-service'
        },
        {
          key: 'details',
          label: '',
          tdClass: 'width-service',
          thClass: 'width-service',
          views: true,
          for: 'all'
        },
        { key: 'type', label: 'TYPE DOCUMENT', isSortable: true },
        { key: 'Vendeur', label: 'SOCIETE', isSortable: true },
        {
          key: 'num',
          label: 'NUMERO',
          isSortable: true
        },
        { key: 'date_creation', label: 'DATE DE CRÉATION', isSortable: true },
        { key: 'Acheteur', label: 'CLIENT', isSortable: true },
        { key: 'etat', label: 'ETAT', isSortable: true },
        {
          key: 'date_paiement',
          label: 'DATE DE PAIEMENT',
          isSortable: true
        },
        { key: 'Categorie', label: 'CATEGORIE', isSortable: true },
        { key: 'total_net_ht', label: 'NET HT', isSortable: true },
        { key: 'total_ttc', label: 'TOTAL TTC', isSortable: true },
        { key: 'confirme', label: 'CONFIRMATION', isSortable: true },
        { key: 'num_proforma', label: 'REFERENCE PROFORMA', isSortable: true },
        { key: 'action', label: '', isSortable: false }
      ],
      modeReglementList: [
        { value: null, text: 'Tous' },
        { value: 'Virement bancaire', text: 'Virement bancaire' },
        { value: 'Carte bancaire', text: 'Carte bancaire' },
        { value: 'Espèses', text: 'Espèses' },
        {
          value: 'LCR Lettre de change Relevé',
          text: 'LCR Lettre de change Relevé'
        },
        { value: 'Prélèvement', text: 'Prélèvement' },
        { value: 'PayPal', text: 'PayPal' }
      ],
      typeFacture: [
        { value: null, text: 'Tous' },
        { value: 'Facture', text: 'Facture' },
        { value: 'Facture Proforma', text: 'Facture proforma' },
        // { value: "Facture d'acompte", text: "Facture d'acompte" },
        // { value: 'Facture de solde', text: 'Facture de solde' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      options: [
        { text: 'Type Facture', value: 'type' },
        { text: 'Societe', value: 'societe' },
        { text: 'Numéro Facture', value: 'numero' },
        { text: 'Client', value: 'client' },
        { text: 'Montant NET HT', value: 'montant_net_ht' },
        { text: 'Montant TVA', value: 'montant_tva' },
        { text: 'Montant TTC', value: 'montant_ttc' },
        { text: 'Statut', value: 'statut' },
        { text: 'Objet', value: 'objet' },
        { text: 'Iban', value: 'iban' },
        { text: 'Bic', value: 'swift' },
        { text: 'Date paiement', value: 'date_paiement' },
        { text: 'Mode reglement', value: 'mode_reglement' },
        { text: 'Date de création', value: 'date' },
        { text: 'Catégorie', value: 'categorie' }
      ],
      optionProduct: [
        { text: 'Nom', value: 'nom_prod' },
        { text: 'Réfernce', value: 'ref_prod' },
        { text: 'Qantité', value: 'qte_prod' },
        { text: 'Unité ', value: 'unite_prod' },
        { text: 'PU HT', value: 'pu_ht_prod' },
        { text: 'Réduction', value: 'reduction_prod' },
        { text: 'NET HT', value: 'net_ht_prod' },
        { text: 'TVA', value: 'tva_prod' },
        { text: 'TTC', value: 'ttc_prod' },
        { text: 'Description', value: 'description_prod' }
      ]
    };
  },
  filters: {
    formateDate: value => {
      return moment(new Date(value)).format('DD-MM-YYYY');
    }
  },
  computed: {
    ...mapGetters([
      'getFacturesLibresTh',
      'getFactureLibreLoadingTh',
      'getFactureLibreErroresTh',
      'getFactureLibreTotalRowsTh',
      'getSettingFilialesTh',
      'getUserData',
      'cantUpdate',
      'cantCreate',
      'cantExport',
      'cantAvoir',
      'cantDuplicate',
      'isSuperAdminGetter',
      'getFiliaeOfResponsable',
      'getAllCategories',
      'getLoadingMailFactureLibre',
      'getErrorMailingFactureLibre',
      'getSuccessSendMailsFactureLibre',
      'getOtherErrorMailFactureLibre'
    ]),
    computedGetDataToUse() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check === true && row.type !== "Facture d'avoir"
      );
      return checkesRows;
    },
    computegetSettingFilialesTh() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computeVendeursNames() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return item.name;
      });
      return vendeurNames;
    },
    computedGetAcheteurs() {
      let acheteurNames = this.getSettingFilialesTh.map(item => {
        // if (item.type === 'particulier' || item.type === 'professionnel') {
        return { value: item.id, text: item.name };
        // }
      });
      return acheteurNames;
    },
    computeGetcategories() {
      return this.getAllCategories.map(item => {
        return { value: item.id, text: item.nom_categorie };
      });
      // return categorieNames;
    },
    computedListProduct() {
      return function(data) {
        if (data && data.produits) {
          let p = '';
          const tableProduit = data.produits.filter(
            item => item.type == 'produit'
          );
          for (let i = 0; i < tableProduit.length; i++) {
            let p1 = i < tableProduit.length - 1 ? ', ' : '';
            p = p + ' ' + tableProduit[i].nom + p1;
          }
          return p;
        }
      };
    },
    computedcheckRolePermission() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_filiale'
      ) {
        return false;
      }
      return true;
    },
    computedWidth() {
      return function(data) {
        if (data && data.etat && data.etat == 'Payé en partie') {
          let montant = (data.montant_payer * 100) / data.total_ttc;
          if (montant > 100) {
            montant = 100;
          }
          return { width: montant + '%' };
        } else {
          return { width: '0%' };
        }
      };
    },
    computedCheck() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      if (checkesRows.length > 0) {
        return true;
      }
      return false;
    },
    computedChekAvoir() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      if (checkesRows.length > 0) {
        return true;
      }
      return false;
    }
  },
  components: {
    DatePicker,
    MailModal
  },
  methods: {
    ...mapActions([
      'fetchAllFacturesLibres',
      'getSettingFilialeTh',
      'updatePaymentDate',
      'downloadPDF',
      'downloadZIP',
      'downloadOnePDF',
      'fetchAllCategories',
      'getSettingFilialeThNew',
      'confirmedBills',
      'CreateBillAvoirGlobal',
      'exportExcelFactureLibre',
      'getFilialsOfConnectedResponsable',
      'getCategories',
      'transformProformatToFacture',
      'sendMailFactureLibre'
    ]),
    initModal() {
      this.selected = [
        'type',
        'numero',
        'date',
        'client',
        'statut',
        'montant_net_ht',
        'montant_ttc',
        'objet',
        'montant_tva',
        'societe',
        'iban',
        'swift',
        'date_paiement',
        'mode_reglement',
        'nom_prod',
        'ref_prod',
        'qte_prod',
        'unite_prod',
        'pu_ht_prod',
        'reduction_prod',
        'net_ht_prod',
        'tva_prod',
        'ttc_prod',
        'description_prod',
        'categorie'
      ];
      this.checkAllColumn = true;
      this.checkAllColumnF = true;
    },
    disabledStartDateFilter(date) {
      return this.date_fin && date >= new Date(this.date_fin);
    },
    disabledEndDateFiltre(date) {
      return this.date_debut && date < new Date(this.date_debut);
    },
    changeAllCheckColumns() {
      if (this.checkAllColumnF == true && this.checkAllColumn === true) {
        this.selected = [
          'type',
          'numero',
          'date',
          'client',
          'statut',
          'montant_net_ht',
          'montant_ttc',
          'objet',
          'montant_tva',
          'societe',
          'iban',
          'swift',
          'date_paiement',
          'mode_reglement',
          'nom_prod',
          'ref_prod',
          'qte_prod',
          'unite_prod',
          'pu_ht_prod',
          'reduction_prod',
          'net_ht_prod',
          'tva_prod',
          'ttc_prod',
          'description_prod',
          'categorie'
        ];
      } else if (
        this.checkAllColumnF == true &&
        this.checkAllColumn === false
      ) {
        this.selected = [
          'nom_prod',
          'ref_prod',
          'qte_prod',
          'unite_prod',
          'pu_ht_prod',
          'reduction_prod',
          'net_ht_prod',
          'tva_prod',
          'ttc_prod',
          'description_prod'
        ];
      } else if (
        this.checkAllColumnF == false &&
        this.checkAllColumn === true
      ) {
        this.selected = [
          'type',
          'numero',
          'date',
          'client',
          'statut',
          'montant_net_ht',
          'montant_ttc',
          'objet',
          'montant_tva',
          'societe',
          'iban',
          'swift',
          'date_paiement',
          'mode_reglement',
          'categorie'
        ];
      } else {
        this.selected = [];
      }
    },
    async handleSendMail(selectedAcheteurVendeur) {
      this.$bvModal.hide('mailModal');
      var bodyFormData1 = new FormData();
      for (let i = 0; i < this.computedGetDataToUse.length; i++) {
        bodyFormData1.append(
          'ids[' + [i] + ']',
          this.computedGetDataToUse[i].id
        );
      }
      bodyFormData1.append('parameter', 'Acheteur');
      var bodyFormData2 = new FormData();
      for (let i = 0; i < this.computedGetDataToUse.length; i++) {
        bodyFormData2.append(
          'ids[' + [i] + ']',
          this.computedGetDataToUse[i].id
        );
      }
      bodyFormData2.append('parameter', 'Vendeur');
      if (selectedAcheteurVendeur.includes('acheteur')) {
        const response = await this.sendMailFactureLibre(bodyFormData1);
        if (response.succes) {
          this?.getErrorMailingFactureLibre?.map(element => {
            if (element.societe.length > 0) {
              this.flashMessage.show({
                status: 'error',
                title: element.message,
                clickable: true,
                time: 0,
                message: element.societe
              });
            }
          });
          if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
            this.flashMessage.show({
              status: 'success',
              title: this.getSuccessSendMailsFactureLibre?.message,
              clickable: true,
              time: 0,
              message: this.getSuccessSendMailsFactureLibre?.societe
            });
          }
        } else {
          this.flashMessage.show({
            status: 'error',
            title: 'ERROR',
            clickable: true,
            time: 0,
            message: this.getOtherErrorMailFactureLibre
          });
        }
      }
      if (selectedAcheteurVendeur.includes('vendeur')) {
        const response2 = await this.sendMailFactureLibre(bodyFormData2);
        if (response2.succes) {
          this?.getErrorMailingFactureLibre?.map(element => {
            if (element.societe.length > 0) {
              this.flashMessage.show({
                status: 'error',
                title: element.message,
                clickable: true,
                time: 0,
                message: element.societe
              });
            }
          });
          if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
            this.flashMessage.show({
              status: 'success',
              title: this.getSuccessSendMailsFactureLibre?.message,
              clickable: true,
              time: 0,
              message: this.getSuccessSendMailsFactureLibre?.societe
            });
          }
        } else {
          this.flashMessage.show({
            status: 'error',
            title: 'ERROR',
            clickable: true,
            time: 0,
            message: this.getOtherErrorMailFactureLibre
          });
        }
      }
    },

    async refrech() {
      this.column = 'id';
      this.ordre = 'DESC';
      await this.fetchAllFacturesLibres({
        per_page: this.perPage,
        page: this.page,
        num: null,
        type: this.type?.filter(item => item?.value != null),
        etat: this.etat?.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement.filter(item => item?.value != null),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie?.filter(item => item?.value != null),
        acheteur_id: this.acheteur?.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        objet: this.objet,
        column: 'id',
        order: 'DESC',
        famille: 'libre'
      });
    },
    async handleExportFile() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      var bodyFormData = new FormData();
      for (let i = 0; i < this.selected.length; i++) {
        bodyFormData.append('columns[' + [i] + ']', this.selected[i]);
      }
      let ids = [];

      for (let i = 0; i < checkesRows.length; i++) {
        if (checkesRows[i].type !== "Facture d'avoir") {
          ids.push(checkesRows[i].id);
          bodyFormData.append('ids[' + [i] + ']', checkesRows[i].id);
        }
      }
      if (this.mode_reglement) {
        // bodyFormData.append('mode_reglement', this.mode_reglement);
        for (let i = 0; i < this.mode_reglement.length; i++)
          if (this.mode_reglement[i].value != null) {
            bodyFormData.append(
              'mode_reglement[' + [i] + ']',
              this.mode_reglement[i].value
            );
          }
      }
      if (this.etat) {
        // bodyFormData.append('etat', this.etat);
        for (let i = 0; i < this.etat.length; i++)
          if (this.etat[i].value != null) {
            bodyFormData.append('etat[' + [i] + ']', this.etat[i].value);
          }
      }
      if (this.type) {
        for (let i = 0; i < this.type.length; i++)
          if (this.type[i].value != null) {
            bodyFormData.append('type[' + [i] + ']', this.type[i].value);
          }
      }
      if (this.num) {
        bodyFormData.append('num', this.num);
      }
      if (this.vendeur) {
        for (let i = 0; i < this.vendeur.length; i++)
          if (this.vendeur[i].value != null) {
            bodyFormData.append(
              'vendeur_id[' + [i] + ']',
              this.vendeur[i].value
            );
          }
      }
      if (this.categorie) {
        for (let i = 0; i < this.categorie.length; i++)
          if (this.categorie[i].value != null) {
            bodyFormData.append(
              'categorie_id[' + [i] + ']',
              this.categorie[i].value
            );
          }
      }
      if (this.acheteur) {
        for (let i = 0; i < this.acheteur.length; i++)
          if (this.acheteur[i].value != null) {
            bodyFormData.append(
              'acheteur_id[' + [i] + ']',
              this.acheteur[i].value
            );
          }
      }
      if (this.objet) {
        bodyFormData.append('objet', this.objet);
      }
      if (this.date_debut) {
        bodyFormData.append('date_debut', this.date_debut);
      }
      if (this.date_fin) {
        bodyFormData.append('date_fin', this.date_fin);
      }
      if (this.column) {
        bodyFormData.append('column', this.column);
      }
      if (this.order) {
        bodyFormData.append('order', this.order);
      }
      bodyFormData.append('famille', 'libre');

      if (this.selected.length === 0) {
        this.error = 'SVP séléctionner au moin une colonne';
        return;
      }
      //  else if (ids.length === 0) {
      //   this.error = 'SVP séléctionner au moin un document de type Facture';
      //   return;
      // }
      const response = await this.exportExcelFactureLibre(bodyFormData);
      if (response) {
        this.$refs['refExportXlsModal'].hide();
        this.error = null;
      }
    },
    setVisibilityEtatList(data, index) {
      data.openSelect = !data.openSelect;
      if (data.openSelect === true) {
        this.indexSelectIsOpen = { index: index, item: data };
      } else {
        this.indexSelectIsOpen = null;
      }
    },
    closeSelectEtat(event) {
      if (
        this.indexSelectIsOpen != null &&
        this.indexSelectIsOpen.item &&
        this.indexSelectIsOpen.item.openSelect == true
      ) {
        let tab = event.path.filter(
          item =>
            item.nodeName == 'DIV' &&
            item.getAttribute('id') ==
              'component' + this.indexSelectIsOpen.index
        );
        if (event.path && tab.length == 0) {
          this.getFacturesLibresTh[
            this.indexSelectIsOpen.index
          ].openSelect = false;
          this.indexSelectIsOpen = null;
        }
      }
    },
    async listOrder(col, ord) {
      this.isLoading = true;
      this.column = col;
      this.order = ord;
      await this.fetchAllFacturesLibres({
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type?.filter(item => item?.value != null),
        etat: this.etat?.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement.filter(item => item?.value != null),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie.filter(item => item?.value != null),
        acheteur_id: this.acheteur.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        objet: this.objet,
        column: col,
        order: ord,
        famille: 'libre'
      });
      this.isLoading = false;
    },
    async changePerPage() {
      this.isLoading = true;
      this.page = 1;
      await this.fetchAllFacturesLibres({
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type?.filter(item => item?.value != null),
        etat: this.etat?.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement?.filter(
          item => item?.value != null
        ),
        vendeur: this.vendeur?.filter(item => item.value != null),
        categorie_id: this.categorie?.filter(item => item?.value != null),
        acheteur_id: this.acheteur?.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        column: this.column,
        order: this.order,
        objet: this.objet,
        famille: 'libre'
      });
      this.isLoading = false;
      this.setLocalStorageFactureLibre();
    },
    visualisation(item) {
      this.$router.push(`/display-facture/${item.id}`);
    },
    async pagination(paginate) {
      this.isLoading = true;
      this.page = paginate;
      await this.fetchAllFacturesLibres({
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type?.filter(item => item?.value != null),
        etat: this.etat?.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement?.filter(
          item => item?.value != null
        ),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie?.filter(item => item?.value != null),
        acheteur_id: this.acheteur?.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        column: this.column,
        order: this.order,
        objet: this.objet,
        famille: 'libre'
      });
      this.isLoading = false;
      this.setLocalStorageFactureLibre();
    },
    async filterSearch() {
      await this.fetchAllFacturesLibres({
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type?.filter(item => item?.value != null),
        etat: this.etat?.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement?.filter(
          item => item?.value != null
        ),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie?.filter(item => item?.value != null),
        acheteur_id: this.acheteur?.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        column: this.column,
        order: this.order,
        objet: this.objet,
        famille: 'libre'
      });
      // this.setLocalStorageFactureLibre();
    },
    checkAllFunction() {
      this?.getFacturesLibresTh?.map(item => (item.check = this.checkAll));
    },
    checkOneFunction(data) {
      data.check == !data.check;
    },
    checkOneAvoirFunction(data) {
      if (data.checkAvoir === true) {
        this.factureAvoirChecked.push(data.id);
      } else {
        let index = this.factureAvoirChecked.findIndex(
          element => element.id === data.id
        );
        this.factureAvoirChecked.splice(index, 1);
      }
    },
    async CreateGlobalAvoir(data) {
      const response = await this.CreateBillAvoirGlobal(data);
      if (response.success) {
        this.flashMessage.show({
          status: 'success',
          title: '',
          clickable: true,
          time: 0,
          message: 'Une avoir sur ' + data.num + ' a été ajoutée'
        });
        await this.fetchAllFacturesLibres({
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          type: this.type?.filter(item => item?.value != null),
          etat: this.etat?.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie?.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
      }
    },
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    async updateEtat(data, result) {
      data.etat = result.value;
      data.openSelect = false;
      await this.updatePaymentDate(data);
    },
    async FacuresToDownloadPDF() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureAvoirChecked.length; i++) {
        ids.push(this.factureAvoirChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      await this.downloadPDF(bodyFormData);
    },
    async FacuresToDownloadOnePDF(idPdf) {
      await this.downloadOnePDF(idPdf);
    },

    async FacuresToDownloadZIP() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureAvoirChecked.length; i++) {
        ids.push(this.factureAvoirChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      await this.downloadZIP(bodyFormData);
    },
    async FacuresToConfirme() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureAvoirChecked.length; i++) {
        ids.push(this.factureAvoirChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      const response = await this.confirmedBills(bodyFormData);
      if (response === true) {
        await this.fetchAllFacturesLibres({
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          type: this.type?.filter(item => item?.value != null),
          etat: this.etat?.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie?.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
      }
    },
    async trasformFacture(id) {
      let bodyFormData = new FormData();
      // for (let i = 0; i < checkesRows.length; i++) {
      //   ids.push(checkesRows[i].id);
      // }

      bodyFormData.append('ids[' + 0 + ']', id);

      const response = await this.transformProformatToFacture(bodyFormData);
      if (response) {
        this.fetchAllFacturesLibres({
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          type: this.type?.filter(item => item?.value != null),
          etat: this.etat?.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie?.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
      }
    },
    checkRole() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_filiale'
      ) {
        let item = this.getSettingFilialesTh.filter(
          element => element.id === this.getUserData.filiale_id
        )[0];
        this.vendeur = item?.name;
      }
    },
    // setLocalStorageFactureLibre() {
    //   localStorage.setItem(
    //     'facture-libre',
    //     JSON.stringify({
    //       num: this.num,
    //       etat: this.etat,
    //       type: this.type,
    //       mode_reglement: this.mode_reglement,
    //       vendeur: this.vendeur,
    //       categorie_id: this.categorie,
    //       acheteur_id: this.acheteur,
    //       date_debut: this.date_debut,
    //       date_fin: this.date_fin,
    //       objet: this.objet
    //     })
    //   );
    // },
    selectAllType() {
      if (this.type.length && this.type[this.type.length - 1]?.value == null) {
        this.type = [{ value: null, text: 'Tous' }];
      } else {
        if (this.type.some(data => data?.value == null)) {
          this.type = this.type.filter(item => item?.value != null);
        }
      }
    },

    selectAllEtat() {
      if (this.etat.length && this.etat[this.etat.length - 1]?.value == null) {
        this.etat = [{ value: null, text: 'Tous' }];
      } else {
        if (this.etat.some(data => data?.value == null)) {
          this.etat = this.etat.filter(item => item?.value != null);
        }
      }
    },

    selectAllMode() {
      if (
        this.mode_reglement.length &&
        this.mode_reglement[this.mode_reglement.length - 1]?.value == null
      ) {
        this.mode_reglement = [{ value: null, text: 'Tous' }];
      } else {
        if (this.mode_reglement.some(data => data?.value == null)) {
          this.mode_reglement = this.mode_reglement.filter(
            item => item?.value != null
          );
        }
      }
    },

    selectAllVendeur() {
      if (
        this.vendeur.length &&
        this.vendeur[this.vendeur.length - 1]?.value == null
      ) {
        this.vendeur = [{ value: null, text: 'Tous' }];
      } else {
        if (this.vendeur.some(data => data?.value == null)) {
          this.vendeur = this.vendeur.filter(item => item?.value != null);
        }
      }
    },

    selectAllAcheteur() {
      if (
        this.acheteur.length &&
        this.acheteur[this.acheteur.length - 1]?.value == null
      ) {
        this.acheteur = [{ value: null, text: 'Tous' }];
      } else {
        if (this.acheteur.some(data => data?.value == null)) {
          this.acheteur = this.acheteur.filter(item => item?.value != null);
        }
      }
    },

    selectAllCategory() {
      if (
        this.categorie.length &&
        this.categorie[this.categorie.length - 1]?.value == null
      ) {
        this.categorie = [{ value: null, text: 'Tous' }];
      } else {
        if (this.categorie.some(data => data?.value == null)) {
          this.categorie = this.categorie.filter(item => item?.value != null);
        }
      }
    }
  },

  async mounted() {
    await this.getCategories();
    this.isLoading = true;
    await this.getFilialsOfConnectedResponsable();
    await this.getSettingFilialeTh();
    // if (localStorage.getItem('facture-libre')) {
    //   const localstorge = JSON.parse(localStorage.getItem('facture-libre'));
    //   this.num = localstorge.num;
    //   this.etat = localstorge.etat;
    //   this.type = localstorge.type;
    //   this.mode_reglement = localstorge.mode_reglement;
    //   this.vendeur = localstorge.vendeur;
    //   this.categorie_id = localstorge.categorie;
    // }
    // this.setLocalStorageFactureLibre();
    // if (sessionStorage.getItem('facture-libre')) {
    //   this.page = sessionStorage.getItem('facture-libre');
    // } else {
    //   sessionStorage.setItem('facture-libre', this.page);
    // }
    // if (
    //   this.getUserData &&
    //   this.getUserData.role &&
    //   this.getUserData.role == 'responsable_filiale'
    // ) {
    //   await this.getSettingFilialeThNew();
    // } else {
    //   await this.getSettingFilialeTh();
    // }
    this.checkRole();
    await this.fetchAllFacturesLibres({
      per_page: this.perPage,
      page: this.page,
      num: this.num,
      type: [],
      etat: [],
      mode_reglement: [],
      vendeur: [],
      categorie_id: [],
      acheteur_id: [],
      date_debut: this.date_debut,
      date_fin: this.date_fin,
      column: this.column,
      order: this.order,
      objet: this.objet,
      famille: 'libre'
    });
    await this.getSettingFilialeTh();
    this.professionnelSocite = this.getSettingFilialesTh.filter(
      element => element.type === 'professionnel'
    );
    this.isLoading = false;
    // await this.fetchAllCategories();
    this.idConnecter = JSON.parse(localStorage.getItem('User-Details')).id;
    this.connectedRole = JSON.parse(localStorage.getItem('User-Details')).role;
  },
  directives: {
    ClickOutside
  }
};
</script>
<style lang="scss" scoped>
* {
  font-family: 'Montserrat', sans-serif;
}
.body-box-rapport {
  height: calc(100vh - 68px);
  .tabs-body-style {
    width: 250px;
  }
  .table-rapport-style {
    .b-table-sticky-header {
      max-height: calc(100vh - 185px) !important;
      height: calc(100vh - 185px);
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .header-component-facture-libre {
      margin: 5px 0px;
      justify-content: space-between;
    }
  }
}

.content-tab-facture-libre {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
}
.block-service-style {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.fix-margin-td-avoir {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-right-width: 0px;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #7978aa;
  border-radius: 7px;
}
.couleur-facture-global {
  background-color: #f0f1ff;
}
.style-filter-div {
  overflow-x: auto;
  height: calc(100vh - 227px);
}
.style-block--filter {
  height: 100%;
}
.column-size {
  width: 140px;
}
.style-arrow-not-selected {
  width: 14px;
  height: 14px;
  color: #aaacb0;
  // margin-top: 3px;
  margin-left: 1px;
  margin-right: 1px;
}
.style-arrow-selected {
  width: 14px;
  height: 14px;
  color: #4d4bac;
  margin-left: 1px;
  margin-right: 1px;
}
.underline-selected-colomn {
  text-decoration: underline;
}
</style>
<style lang="scss">
.button-13 {
  background-color: #f0f1f2;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
  align-items: center;
  justify-content: center;
  &:focus {
    border-color: #008296;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    outline: 0;
  }
}
.custom-component-etat-facture {
  display: block;
  position: relative;
  .b-form-select-custom-etat {
    height: 18px;
    border-radius: 100px;
    border-color: #70707038;
    width: 75%;
    margin: auto;
    padding: 0px;
    -moz-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 1px 5px -2px rgb(0 0 0 / 75%);
    min-width: 100px;
    font-size: 89%;
    position: relative;
    cursor: pointer;
  }
  &.open {
    .dropdown-menu {
      display: block;
      min-width: 9rem;
    }
    li {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.428571429;
      color: #333333;
      white-space: nowrap;
      font-size: 10px;
      &:hover {
        background-color: #4d4bac;
        color: white;
      }
    }
  }
  #content {
    position: relative;
    z-index: 1;
    text-align: center;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
  #background {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 1;
    border-radius: 100px;
    box-shadow: 2px 1px 5px -2px rgb(0 0 0 / 75%);
  }
  .icon-class-caret-down {
    margin-left: 3px;
    height: 1em !important;
  }
}
.table-factures-libres-style {
  max-height: calc(100vh - 247px) !important;
  height: calc(100vh - 247px) !important;
  margin-bottom: 0px;
  max-width: 100%;
}
.header-component-facture-libre {
  .btn-secondary {
    background-color: #4d4bac;
    border-color: #4d4bac;
    font-size: 10px;
  }
}
.table-rapport-style .customTableFraisFactureLibre {
  .item-details-style {
    margin: 2px 0px;
  }
  td,
  th {
    font-size: 8.5px;
    padding: 2px 2px !important;
    min-width: 100px;
    width: 100px;
  }
  .width-service {
    min-width: 40px;
    width: 40px;
  }
}
.three-points-concats {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}
.white-color-custom-select {
  background-color: #fff;
  color: #000;
}
.red-color-custom-select {
  background: linear-gradient(
    to bottom,
    #f85032 0%,
    #f16f5c 0%,
    #f6290c 51%,
    #f02f17 71%,
    #e73827 100%
  );
  color: #fff;
}
.green-color-custom-select {
  background: linear-gradient(to bottom, #82bc40 0%, #47761d 100%);
  color: #fff;
}
.blue-color-custom-select {
  background: linear-gradient(#54b3ff, #0078d9);
  color: #fff;
}
.style-filtre {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
input::placeholder {
  font-size: 12px;
  color: #d5d9d9;
}
.align-td-item {
  // justify-content: space-between;
  // display: flex;
  padding: 0px !important;
}
.customSelectMultipleFilter.libres {
  width: 100%;
  min-height: 29px !important;
  .multiselect__placeholder {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .multiselect__tags {
    padding: 3px 32px 0px 17px;
    min-height: 21px;
    border-radius: 50px !important;
    width: 100%;
  }
  .multiselect__select {
    width: 30px;
    height: 29px;
  }
  .multiselect__spinner {
    height: 27px;
    border-radius: 0px 50px 50px 0px;
    width: 22px;
  }
  .multiselect__option {
    padding: 4px;
    min-height: 27px;
    line-height: 16px;
    font-size: 11px !important;
  }
  .multiselect__single {
    display: none;
    font-size: 9px !important;
    height: 21px;
  }
  .multiselect__input {
    font-size: 9px !important;
    height: 21px;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8cb7;
    border-radius: 7px;
  }
  .showBlock {
    display: block;
    font-size: 9px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
  }
}

// .customSelectMultipleFilter.libres .multiselect__input,
// .customSelectMultipleFilter.libres .multiselect__single {
//   font-size: 9px !important;
//   height: 21px;
// }
// .libres .multiselect__option {
//   padding: 4px;
//   min-height: 27px;
//   line-height: 16px;
//   font-size: 11px !important;
// }
.libres .multiselect__option.multiselect__option--selected {
  background: #41b883;
  outline: none;
  color: #fff;
}

.libres .multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
}
// .libres .multiselect__select:before {
//   position: relative;
//   right: 0;
//   top: 65%;
//   color: #999;
//   margin-top: 4px;
//   border-color: #999 transparent transparent;
//   border-style: solid;
//   border-width: 5px 5px 0;
//   content: '';
// }

// .customSelectMultipleFilter.libres .multiselect__tags {
//   padding: 3px 32px 0px 17px;
//   min-height: 21px;
//   border-radius: 50px !important;
//   width: 100%;
// }
// .customSelectMultipleFilter.libres .multiselect__select {
//   width: 30px;
//   height: 29px;
// }
// .customSelectMultipleFilter.libres {
//   width: 100%;
//   min-height: 29px !important;
// }
// .customSelectMultipleFilter.libres .multiselect__placeholder {
//   font-size: 11px;
//   margin-bottom: 5px;
// }
// .customSelectMultipleFilter.libres .showBlock {
//   display: block;
//   font-size: 9px;
//   font-weight: 600;
//   margin-bottom: 2px;
//   margin-top: 2px;
// }
.add-facture-style {
  display: inline-block;
  margin-left: 14% !important;
  margin-bottom: 13px !important;
}
</style>
